import {
  ClientSideNotifications,
  ErrorNotifications,
  INotificationResponse,
  NotificationsChat,
  PossibleResponse,
} from 'types';
import { pull } from 'lodash';
import { io, Socket } from 'socket.io-client';
import {
  chatErrorListeners,
  chatClientSideConnectionListeners,
  defaultChatListeners,
} from 'constants/webSocket';
import { webSocketService } from './WebSocketService';

class ChatWebSocketService {
  ws: Socket | null = null;

  connectInterval: number | null = null;

  pingInterval: number | null = null;

  timeout = 250;

  token: string | null = null;

  isConnecting: boolean = false;

  listeners: {
    [key in NotificationsChat]: Array<(notification: PossibleResponse) => void>;
  } = defaultChatListeners as {
    [key in NotificationsChat]: Array<(notification: PossibleResponse) => void>;
  };

  socketConnectionClientListeners: {
    [key in ClientSideNotifications]: Array<(notification: string) => void>;
  } = chatClientSideConnectionListeners as {
    [key in ClientSideNotifications]: Array<(notification: string) => void>;
  };

  errorListeners: {
    [key in ErrorNotifications]: Array<(notification: string) => void>;
  } = chatErrorListeners as {
    [key in ErrorNotifications]: Array<(notification: string) => void>;
  };

  manuallyClosed = false;

  connect = (token: string) => {
    this.cleanupSocket();

    this.token = token;
    this.isConnecting = true;

    const socket = io(
      process.env.REACT_APP_CHAT_WEBSOCKET_URL || 'wss://stage-chat.holi.solutions/dealer',
      {
        extraHeaders: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    socket.on('connect', () => {
      console.log('Websocket chat connected');
      this.ws = socket;
      this.manuallyClosed = false;
      this.isConnecting = false;

      if (this.pingInterval) {
        clearInterval(this.pingInterval);
      }
      this.pingInterval = setInterval(() => {
        this.sendMessage({
          type: 'PING',
        });
      }, 30000) as any;

      this.timeout = 250;
      if (this.connectInterval) {
        clearTimeout(this.connectInterval);
      }
    });

    socket.on('disconnect', () => {
      console.log('Websocket chat closed');

      this.ws?.close();

      if (!webSocketService.isConnected()) {
        this.cleanupSocket();
      }

      if (!this.manuallyClosed) {
        this.timeout += this.timeout;
        this.connectInterval = setTimeout(() => this.retry(), Math.min(10000, this.timeout)) as any;
      }

      this.listeners = Object.keys(NotificationsChat).reduce(
        (acc, cur) => ({
          ...acc,
          [cur]: [],
        }),
        {},
      ) as {
        [key in NotificationsChat]: Array<(notification: PossibleResponse) => void>;
      };
    });

    socket.on('error', (err) => {
      console.log('Websocket chat error', err);

      socket.close();
      this.isConnecting = false;
    });

    socket.on('connect_error', (err) => {
      console.log('Websocket chat connect_error ', err);
      this.isConnecting = false;
    });

    Object.keys(this.listeners).forEach((listenerKey) =>
      socket.on(listenerKey, (event) => {
        this.listeners[listenerKey as NotificationsChat].forEach((listener) => listener(event));
      }),
    );

    Object.keys(this.socketConnectionClientListeners).forEach((listenerKey) =>
      socket.on(listenerKey, () => {
        this.socketConnectionClientListeners[listenerKey as ClientSideNotifications].forEach(
          (listener) => listener(listenerKey),
        );
      }),
    );

    Object.keys(this.errorListeners).forEach((listenerKey) =>
      socket.on(listenerKey, (error) => {
        this.errorListeners[listenerKey as ErrorNotifications].forEach((listener) =>
          listener(error.message),
        );
      }),
    );
  };

  onMessage(listener: (notification: PossibleResponse) => void, type: NotificationsChat) {
    this.listeners[type].push(listener);
    return () => {
      pull(this.listeners[type], listener);
    };
  }

  onClientMessage(listener: (notification: string) => void, type: ClientSideNotifications) {
    this.socketConnectionClientListeners[type].push(listener);
    return () => {
      pull(this.socketConnectionClientListeners[type], listener);
    };
  }

  onErrorMessage(listener: (notification: string) => void, type: ErrorNotifications) {
    this.errorListeners[type].push(listener);
    return () => {
      pull(this.errorListeners[type], listener);
    };
  }

  sendMessage(message: { type: string; data?: object }) {
    console.log('Websocket chat send message', message);
    const { ws } = this;

    return new Promise((resolve) => {
      if (ws) {
        ws.emit(message.type, message, (response: INotificationResponse<any>) => resolve(response));
      }
    });
  }

  retry = () => {
    console.log('Websocket chat retry connect');
    const access_token = sessionStorage.getItem('access_to_ws_token');
    const { ws, token } = this;
    if (!ws || ws.connected === false) {
      if (access_token && token) {
        this.connect(token);
      }
    }
  };

  isConnected() {
    return this.ws && this.ws.connected === true;
  }

  isChatConnecting() {
    return this.isConnecting === true;
  }

  disconnect() {
    this.manuallyClosed = true;
    this.ws?.close();
    this.cleanupSocket();
  }

  cleanupSocket() {
    if (this.ws) {
      this.ws.removeAllListeners();
      this.ws = null;
    }

    this.token = null;
    this.isConnecting = false;

    if (this.pingInterval) {
      clearInterval(this.pingInterval);
      this.pingInterval = null;
    }

    if (this.connectInterval) {
      clearTimeout(this.connectInterval);
      this.connectInterval = null;
    }
  }
}

export const chatWebSocketService = new ChatWebSocketService();
